import axios from "axios";
import config from "@config";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";
//import store from "@/store";

/**
 * load content blocks
 *
 * @param {integer} blockId
 * @returns array of content blocks
 */
export async function loadContentBlocks(blockId) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    "{ cmsBlocks(identifiers: " +
    JSON.stringify(blockId) +
    ") {items {identifier title content }}}";

  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadContentBlocks", "data-resolver cms", e)();
    throw e;
  });
  return retval.data.data.cmsBlocks;
}

/**
 * Load content page from maggento
 * 
 * @param {integer} id 
 `* @returns return content page object or false
 */
export async function loadContentPage(id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    "{ cmsPage(id: " +
    id +
    ") {url_key title content content_heading page_layout meta_title meta_description meta_keywords}}";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadContentPage", "data-resolver cms", e)();
    throw e;
  });
  if (retval.data.data.cmsPage != null) {
    return retval.data.data.cmsPage;
  } else {
    return false;
  }
}

/**
 * Load content page from maggento
 * 
 * @param {string} id 
 `* @returns return content page object or false
 */
export async function loadContentPageByIdentifier(id) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    '{ cmsPage(identifier: "' +
    id +
    '") {url_key title content content_heading page_layout meta_title meta_description meta_keywords}}';
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadContentPageByIdentifier", "data-resolver cms", e)();
    throw e;
  });
  if (retval.data.data.cmsPage != null) {
    return retval.data.data.cmsPage;
  } else {
    return false;
  }
}

/**
 * loadphysical stores
 *
 * @returns array of physical stores
 */
export async function loadStores() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };

  const query =
    "{phisicalStores{ items { name url_key pickup_location_code street postcode city country_id phone fax email description latitude longitude banner_image {url label} map_image {url label} base_image {url label} storefront_image {url label} images {url label} opening_hours {day, label, times {from to} } alternate_opening_hours {date, times{from to} reason } } }}";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadContentPageByIdentifier", "data-resolver cms", e)();
    throw e;
  });
  if (retval.data.data.phisicalStores != null) {
    return retval.data.data.phisicalStores.items;
  } else {
    return false;
  }
}

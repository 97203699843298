import { Logger } from "@storefront/core/lib/logger";
import axios from "axios";
import config from "@config";
import { getCurrentLanguage } from "@storefront/core/i18n";

const state = () => ({
    categorySliders: [],
});

const actions = {
    async categorySliders(_, { id: id }) {
        const lang = getCurrentLanguage();
        const storelang = config.languages[lang];
        const storeview = storelang["storeview"];

        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + config.shop.accessToken,
            Store: storeview,
        };
        const query =
            '{ getTailoredSlider(pageType: "categories" pageId:' +
            id +
            ") { identifier title fullwidth location slides { title description link buttonText media { url type previewType } } } }";
        const retval = await axios({
            url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
            method: "GET",
            headers: headers,
        }).catch((e) => {
            Logger.error("loadSliderById", "data-resolver sliders", e)();
            throw e;
        });
        if (retval && retval.data && retval.data.data.getTailoredSlider) {
            return retval.data.data.getTailoredSlider;
        } else {
            return [];
        }
    },
    async loadCategorySliders({ dispatch, commit }, { id: id }
    ) {
        const retval = await dispatch('categorySliders', { id });
        if (retval) {
            commit('setCategorySliders', retval);       
        }
    }
};

const mutations = {
    setCategorySliders(state, payload) {
        state.categorySliders = payload;
    },
};
const getters = {
    getCategorySliders: (state) => state.categorySliders,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
